/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "스탠다드아웃 67번째 로그에서는 RDS 인증서 업데이트, 파이썬 2.7 지원 종료, TIOBE 프로그래밍 언어 순위, 스톡옵션 등에 대해 이야기를 나눴습니다."), "\n", React.createElement(_components.h2, null, "아마존 RDS 인증서 교체"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.aws.amazon.com/ko_kr/AmazonRDS/latest/UserGuide/UsingWithRDS.SSL-certificate-rotation.html"
  }, "SSL/TLS 인증서 교체 - Amazon Relational Database Service")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/rds/aurora/"
  }, "데이터 베이스 관리 시스템 | MySQL | Amazon Web Services")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/certificate-manager/"
  }, "AWS Certificate Manager - Amazon Web Services (AWS)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.aws.amazon.com/ko_kr/acm/latest/userguide/how-domain-validation-works.html"
  }, "도메인 검증 작업 방법 - AWS Certificate Manager")), "\n"), "\n", React.createElement(_components.h2, null, "파이썬 2.7 지원 종료"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/news--python-2-7-retired"
  }, "파이썬(Python) 2.7 공식 지원 종료 | 44bits.io")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/news--ruby-2-7-released"
  }, "프로그래밍 언어 루비(Ruby) 2.7 릴리스 및 주요 변경 사항: 패턴 매칭 문법, REPL 개선, 위치 인자와 키워드 인자 분리, 번호 파라미터 등 | 44bits.io")), "\n"), "\n", React.createElement(_components.h2, null, "TIOBE 프로그래밍 언어 순위"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.tiobe.com/tiobe-index/"
  }, "index | TIOBE - The Software Quality Company")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.tiobe.com/tiobe-index/programming-languages-definition/"
  }, "Programming Languages Definition | TIOBE - The Software Quality Company")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.tiobe.com/tiobe-index/c/"
  }, "C | TIOBE - The Software Quality Company")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://octoverse.github.com/"
  }, "The State of the Octoverse | The State of the Octoverse celebrates a year of building across teams, time zones, and millions of merged pull requests.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.tiobe.com/tiobe-index/ruby/"
  }, "Ruby | TIOBE - The Software Quality Company")), "\n"), "\n", React.createElement(_components.h2, null, "스톡옵션"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.hani.co.kr/arti/politics/assembly/924088.html"
  }, "민주당 ‘7호 영입인재’ 이용우 카카오뱅크 대표, 누구? : 국회·정당 : 정치 : 뉴스 : 한겨레")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.businesspost.co.kr/BP?command=article_view&num=96508"
  }, "Who Is ? - 이용우 카카오뱅크 공동대표이사")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://news.bizwatch.co.kr/article/finance/2019/08/22/0022"
  }, "'분기 연속 흑자' 카카오뱅크, 내년 증시 상장 순항")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.rocketpunch.com/"
  }, "로켓펀치 - 비즈니스 네트워크")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://law.go.kr/%EB%B2%95%EB%A0%B9/%EC%83%81%EB%B2%95/%EC%A0%9C340%EC%A1%B0%EC%9D%982"
  }, "상법 - 제340조의2(주식매수선택권)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://magazine.hankyung.com/business/apps/news?popup=0&nid=01&c1=1001&nkey=2017020601106000151&mode=sub_view"
  }, "사내벤처로 시작해 ‘성공 신화’ 쓴 기업들")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://glaw.scourt.go.kr/wsjo/lawod/sjo192.do?lawodNm=%EC%83%81%EB%B2%95&jomunNo=542&jomunGajiNo=3"
  }, "상법 - 제542조의3 (주식매수선택권)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.law.go.kr/%EB%B2%95%EB%A0%B9/%EC%A1%B0%EC%84%B8%ED%8A%B9%EB%A1%80%EC%A0%9C%ED%95%9C%EB%B2%95/%2820200101,16835,20191231%29/%EC%A0%9C16%EC%A1%B0"
  }, "조세특례제한법 - 제16조(중소기업창업투자조합 출자 등에 대한 소득공제), 2, 3 ,4")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
